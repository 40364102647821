import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import {
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Box,
} from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import ChatIcon from "@mui/icons-material/Chat";
import VolunteerActivismOutlinedIcon from '@mui/icons-material/VolunteerActivismOutlined';
import OrvaSphere from "./orvasphere";
import "./chatbot.css";
import { useAuth } from './authcontext';
import { useWebSocket } from "./websocketcontext";
import { useNavigate } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';

interface Message {
  text: string;
  sender: "user" | "bot";
  complete: boolean;
}

const greeting = "I am here to answer any questions you may have about your health, " 
  + "medication and your overall treatment plan. "
  +  "How can I help you today?";

const ChatSocket: React.FC = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [chatMessages, setChatMessages] = useState<Message[]>([]);
  const [input, setInput] = useState<string>("");
  const { sendWsMessage, wsMessage, isMessageComplete, resetMessage } = useWebSocket();

  const messagesEndRef = useRef<HTMLDivElement>(null);
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "auto" });
  };

  useEffect(() => {
    if (!user) {
      navigate('/login');
    }
  }, [user, navigate]);

  useLayoutEffect(() => {
    scrollToBottom();
  }, [wsMessage]);

  const unescapeNewLines = (text: string) => {
    return text.replace(/\\n/g, '\n');
  }

  useEffect(() => {
    if (wsMessage != "") {

      const msg = unescapeNewLines(wsMessage);

      setChatMessages((prevMessages) => {
        const lastMessage =
          prevMessages.length > 0
            ? prevMessages[prevMessages.length - 1]
            : null;

        if (lastMessage && !lastMessage.complete) {
          // Update last message, append text, and possibly mark as complete
          return [
            ...prevMessages.slice(0, -1),
            { ...lastMessage, text: msg, complete: isMessageComplete },
          ];
        } else {
          // Start a new message
          return [
            ...prevMessages,
            { text: msg, sender: "bot", complete: false },
          ];
        }
      });
    }
    if (isMessageComplete) {
      resetMessage();
    }

  }, [isMessageComplete, wsMessage, resetMessage]);

  useEffect(() => {
    if (chatMessages.length == 0) {
      setChatMessages([
        ...chatMessages,
        { text: "Welcome " + user + ". " + greeting, sender: "bot", complete: true }
      ]);
    }
  }, [])

  const handleSend = async () => {
    if (input.trim()) {
      setChatMessages([
        ...chatMessages,
        { text: input, sender: "user", complete: true },
      ]);
      setInput("");
      sendWsMessage(input);
    }
  };

  const lastMessageSender =
    chatMessages.length > 0
      ? chatMessages[chatMessages.length - 1].sender
      : null;


  return (
    <div className="chat-container">
      <div className="messages">
        {chatMessages.map((msg, index) => (
          <div
            key={index}
            className={
              msg.sender === "user" ? "message-row user" : "message-row bot"
            }
          >
            {msg.sender === "bot" && (
              <OrvaSphere />
            )}
            <Card className="message">
              <CardContent>
                <Typography variant="body1">
                  <ReactMarkdown>
                    {msg.text}
                  </ReactMarkdown>
                </Typography>
              </CardContent>
            </Card>
            {msg.sender === "user" && (
              <AccountCircle className="icon" style={{ fontSize: "48px" }} />
            )}
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>
      <Box
        display="flex"
        justifyContent={
          lastMessageSender === "user" ? "flex-end" : "flex-start"
        }
        width="100%"
      >
        <TextField
          style={{ width: "100%" }}
          variant="outlined"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Type your message..."
          onKeyPress={(event) => (event.key === "Enter" ? handleSend() : null)}
        />
        <Button variant="contained" color="primary" onClick={handleSend}>
          Send
        </Button>
      </Box>
    </div>
  );
};

export default ChatSocket;
