import * as React from "react";
import { useState, ReactElement } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import axios from "axios";
import { useAuth } from './authcontext';
import { useNavigate } from "react-router-dom";
import { log } from "console";

axios.defaults.withCredentials = true;

const LoginForm: React.FC = (): ReactElement => {
  const { setUser } = useAuth();
  const [state, setState] = useState({
    user: "",
    password: "",
    error: undefined,
  });

  const handleChange = (e: any) => {
    setState({
      ...state,
      [e.target.id]: e.target.value,
    });
  };

  const navigate = useNavigate();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      const response = await axios.post(
        "https://asmovian.net:8000/signin",
        {
          user: state.user,
          password: state.password,
        },
        {
          withCredentials: true, // Ensure cookies are included
        }
      );

      console.log(response);

      if (response.status === 200) {
        //sessionStorage.setItem("userAuthenticated", "true");
        console.log("Login successful: ", {response});
        console.log("Setting user to: ", state.user);
        //localStorage.setItem('idToken', response.data.idToken);
        setUser(response.data.name);
        navigate("/home");
      }

      // lets see if the httpOnly cookie is attached
      //const response2 = await axios.get("http://localhost:8000/langchain", {
        // withCredentials: true,
      // });
      // console.log(response2); 

      // Assuming the API returns the idToken or some form of access token on successful authentication
      // if (response.data.idToken) {
      //   // Optionally, store the token in localStorage or session
      //   localStorage.setItem('idToken', response.data.idToken);
      //   navigate('/home')
      // } else {
      //   throw new Error('Authentication failed, no token received.');
      // }
    } catch (error) {
      console.error("Login error:", error);
      // Redirect back to the login page on error or failed authentication
      navigate("/login");
    }
  };

  return (
    <>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="user"
              label="Email Address"
              name="user"
              autoComplete="user"
              autoFocus
              onChange={handleChange}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={handleChange}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default LoginForm;
