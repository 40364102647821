import React, { createContext, useContext, useState } from 'react';

import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import LoginPage from './pages/login';
import HomePage from './pages/home';
import Protected from './pages/protected';
import { AuthProvider } from './components/authcontext';
import { WebSocketProvider } from './components/websocketcontext';

function App() {

  return (
    <Router>
    <AuthProvider>
    <WebSocketProvider>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="/protected" element={<Protected />} />
      </Routes>
    </WebSocketProvider>
    </AuthProvider>
    </Router>
  );
}

export default App;
