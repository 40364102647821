import { EventBusyTwoTone } from '@mui/icons-material';
import React, { createContext, useContext, useEffect, useRef, useState, ReactNode } from 'react';
import { useAuth } from './authcontext';
import { useNavigate } from 'react-router-dom';

// Define the type for the context state
interface WebSocketContextType {
    sendWsMessage: (message: string) => void;
    wsMessage: string;  // Array of messages for demonstration
    isMessageComplete: boolean;  // Flag to indicate message completion
    resetMessage: () => void;  // Function to reset the message state
}

// Create the WebSocket context
const WebSocketContext = createContext<WebSocketContextType | undefined>(undefined);

// Custom hook to use the WebSocket context
export const useWebSocket = () => {
    const context = useContext(WebSocketContext);
    if (context === undefined) {
        throw new Error('useWebSocket must be used within a WebSocketProvider');
    }
    return context;
};

// Define interface for WebSocketProvider props
interface WebSocketProviderProps {
    children: ReactNode;
}

// Provider component
export const WebSocketProvider: React.FC<WebSocketProviderProps> = ({ children }) => {
    const [isConnected, setIsConnected] = useState(false);
    const [isMessageComplete, setMessageComplete] = useState(false);
    const [wsMessage, setWsMessage] = useState("");
    const ws = useRef<WebSocket | null>(null);
    const { user, logout } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        //console.log("WebSocketProvider mounted.");
        // Establish WebSocket connection
        if (!ws.current || ws.current.readyState === WebSocket.CLOSED) {
            //console.log("Creating websocket ...");
            ws.current = new WebSocket("wss://asmovian.net:8000");
        }
        //ws.current = new WebSocket('ws://localhost:8000');
        ws.current.onmessage = (event) => {
            //console.log("onmessage: ", event.data);
            if (event.data == "done") {
                //console.log("------ detected done:");
                setMessageComplete(true);
            } else {
                const event_text = event.data.replace(/["]+/g, '');
                setWsMessage(prevMessage => prevMessage + event_text);
            }
        };
        ws.current.onclose = () => console.log("WebSocket disconnected.");
        ws.current.onerror = (error) => {
            console.error("WebSocket error:", error);
            // we are going to logout as the most likely cause of the websocket
            // error is that we are not authenticated (i.e. cookie expired)
            logout();
            navigate("/login");
        };
        ws.current.onopen = () => { 
            setIsConnected(true);
            //console.log("WebSocket connected.");
        };

        // Cleanup on unmount
        return () => {
            //console.log("WebSocketProvider unmounted.");
            if (isConnected) {
                ws.current?.close();
            }
        };
    }, [user]);

    const sendWsMessage = (message: string) => {
        if (ws.current?.readyState === WebSocket.OPEN) {
            ws.current.send(message);
        }
    };

    const resetMessage = () => {
        setWsMessage('');
        setMessageComplete(false);  // Reset the completion state
    };

    return (
        <WebSocketContext.Provider value={{ sendWsMessage, wsMessage, isMessageComplete, resetMessage }}>
            {children}
        </WebSocketContext.Provider>
    );
};
