import React, { useEffect } from "react";
import { ReactElement } from "react";
import { useAuth } from './authcontext';
import { useNavigate } from 'react-router-dom';

const Protected: React.FC = (): ReactElement => {
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate('/login');
    } else {
      console.log("Success: user is set");
    }

  }, [user, navigate]);

  return (
    <div>
      <h2>Protected Component</h2>
      {user ? <p>Welcome, {user}!</p> : <p>Not authenticated</p>}
    </div>
  );

};

export default Protected;
