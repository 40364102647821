import React, { useEffect } from "react";
import ChatSocket from "../components/chatsocket";

const HomePage = () => {

  return (
    <main style={{ padding: "1rem 0" }}>
      <ChatSocket />
    </main>
  );
};

export default HomePage;
