import React from "react";
import orvaIcon from "../assets/orva-sphere-48px.png"; 

const OrvaSphere = () => {
  return (
    <div>
      <img src={orvaIcon} alt="Orva Icon" className="icon" style={{ width: "48px", height: "48px" }} />
    </div>
  );
};

export default OrvaSphere;